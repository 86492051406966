import CommonReply from "*.vue";
<template>
    <CommonView/>
</template>

<script>
    import CommonView from "@/components/common/CommonView.vue";
    export default {
        name: "Bview",
        components: {
            CommonView
        }
    }
</script>

<style scoped>

</style>
